@use './_AutocompleteSearch';

$main-color: #4515a2;
$light-main-color: #9567f0;
$dark-main-color: #2f0f70;

$super-light-main: #ebe1ff;
$white: #ffffff;

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v34-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v34-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/open-sans-v34-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v34-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v34-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans-v34-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans-v34-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans-v34-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans-v34-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

* {
  margin: 0;
  padding: 0;
}

#main {
  background-color: $super-light-main;
}

#app {
  background-color: $white;
}

.textBlock {
  h1 {
    margin-top: 32px;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 32px;
    margin-bottom: 8px;
  }
  h3 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  h5 {
    margin-top: 16px;
  }
  h6 {
    margin-top: 8px;
  }
}
