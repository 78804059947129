//styling the flags
//disable detached mode
:root {
  --aa-icon-size: 40px;
}

// styling the searchbox
.aa-Form,
.aa-DetachedSearchButton {
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.6);
  &:focus-within {
    background-color: #fff;
  }
  min-width: 20ch;
}

.homepage-search .aa-Form {
  height: 60px;
  box-shadow: rgb(0 0 0 / 40%) 0px 6px 20px 0px;
  background-color: #fff;
}

// panel is list fo results
.aa-Panel {
  border-radius: 20px;
  z-index: 9999;
}

// border-radius for highlighted item
.aa-Item {
  border-radius: 15px;
}

.aa-Label .aa-SubmitIcon {
  color: rgba(69, 21, 162, 0.8);
}

.aa-Input::placeholder,
.aa-DetachedSearchButtonPlaceholder {
  color: rgba(69, 21, 162, 0.8);
}

// add algolia credits
.aa-PanelLayout {
  padding-bottom: 12px;

  .aa-List::after {
    content: 'search powered by algolia';
    font-size: 0.75em;
    color: #999;
    padding-left: 8px;
  }
}
